import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { LanguageEnum, Maybe } from '@/graphql/access/generated/access_graphql'
import { setDayJsLocale } from '@/locales/dayJsConfig'

export enum FELanguageEnum {
  EnUs = 'en-US',
  EnAu = 'en-AU',
  EsEs = 'es-ES',
  ThTh = 'th-TH',
  FrFr = 'fr-FR',
  JaJp = 'ja-JP',
  ZhCn = 'zh-CN',
  ZhTw = 'zh-TW',
  ZhHk = 'zh-HK',
  ZhSg = 'zh-SG',
}

export const i18nLocaleMapping: Record<LanguageEnum, FELanguageEnum> = {
  [LanguageEnum.EnUs]: FELanguageEnum.EnUs,
  [LanguageEnum.EnAu]: FELanguageEnum.EnAu,
  [LanguageEnum.EsEs]: FELanguageEnum.EsEs,
  [LanguageEnum.ThTh]: FELanguageEnum.ThTh,
  [LanguageEnum.FrFr]: FELanguageEnum.FrFr,
  [LanguageEnum.JaJp]: FELanguageEnum.JaJp,
  [LanguageEnum.ZhCn]: FELanguageEnum.ZhCn,
  [LanguageEnum.ZhTw]: FELanguageEnum.ZhTw,
  [LanguageEnum.ZhHk]: FELanguageEnum.ZhHk,
  [LanguageEnum.ZhSg]: FELanguageEnum.ZhSg,
}

const addResourceBundle = async (locale: FELanguageEnum) => {
  try {
    // eslint-disable-next-line prettier/prettier
    const translation = await import(/* webpackChunkName: "locale-[request]" */ `./${locale}/translation.json`)
    i18n.addResourceBundle(locale, 'translation', translation.default)
    setDayJsLocale(locale)
    i18n.changeLanguage(locale)
    await localStorage.setItem('appLocale', locale)
  } catch (error) {
    console.log(`Could not load locale ${locale}:`, error)
  }
}

export const selectedLocale = (locale?: Maybe<LanguageEnum>): FELanguageEnum => {
  const savedLocale = localStorage.getItem('appLocale')
  if (locale) {
    return i18nLocaleMapping[locale] || i18nLocaleMapping[LanguageEnum.EnAu]
  } else {
    const isValidLocale = Object.values(FELanguageEnum).includes(savedLocale as FELanguageEnum)
    return isValidLocale ? (savedLocale as FELanguageEnum) : i18nLocaleMapping[LanguageEnum.EnAu]
  }
}

const i18nextConfig = (locale?: Maybe<LanguageEnum>) => {
  const savedLocale = localStorage.getItem('appLocale')
  const currentLocale = locale && i18nLocaleMapping[locale]

  if (!locale || (savedLocale && currentLocale && currentLocale !== savedLocale)) {
    i18n.use(initReactI18next).init({
      resources: {},
      returnNull: undefined,
      interpolation: {
        escapeValue: false,
      },
      lng: selectedLocale(locale),
      fallbackLng: i18nLocaleMapping[LanguageEnum.EnAu],
    })
  }

  addResourceBundle(selectedLocale(locale))

  return i18n
}

export default i18nextConfig
