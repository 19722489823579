import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/en-au'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/th'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-hk'
import 'dayjs/locale/zh-tw'

import { FELanguageEnum } from '@/locales/i18nextConfig'

// Mapping function to convert 'en-US' format to Day.js expected locale format

export function mapLocaleToDayjs(localeToMap: FELanguageEnum): string {
  const localeMap: Record<FELanguageEnum, string> = {
    [FELanguageEnum.EnUs]: 'en',
    [FELanguageEnum.EnAu]: 'en-au',
    [FELanguageEnum.EsEs]: 'es',
    [FELanguageEnum.FrFr]: 'fr',
    [FELanguageEnum.JaJp]: 'ja',
    [FELanguageEnum.ThTh]: 'th',
    [FELanguageEnum.ZhCn]: 'zh-cn',
    [FELanguageEnum.ZhHk]: 'zh-hk',
    [FELanguageEnum.ZhSg]: 'zh-cn', // 'zh-SG' is not supported by Day.js
    [FELanguageEnum.ZhTw]: 'zh-tw',
  }

  // Extract the base locale (e.g., 'en-US' to 'en') if specific mapping is not found
  const baseLocale = localeToMap.split('-')[0].toLowerCase()
  return localeMap[localeToMap] || (Object.values(localeMap).includes(baseLocale) && baseLocale) || 'en-au'
}

export const setDayJsLocale = (locale: FELanguageEnum) => {
  dayjs.locale(mapLocaleToDayjs(locale))
}
